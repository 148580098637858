@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin.scss";

.plan-table {
  @include ag-theme-material(
    (
      material-primary-color: rgba(76, 175, 80, 1),
      material-accent-color: rgba(76, 175, 80, 1),
      row-hover-color: rgba(76, 175, 80, 0.2),
      header-cell-hover-background-color: rgba(76, 175, 80, 0.1),
      borders-side-button: ag-derived(borders),
      font-family: (
        -apple-system,
        BlinkMacSystemFont,
        "Helvetica Neue",
        "Helvetica",
        sans-serif,
      ),
      font-size: 12px,
      grid-size: 4px,
      icon-size: 12px,
    )
  );

  .ag-header {
    height: 52px !important;
    min-height: 52px !important;
  }

  .ag-header-row {
    height: 51px !important;
  }

  .ag-header-cell-label {
    font-size: 12px;
    font-family: (-apple-system, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", sans-serif);
  }

  .ag-header-icon > .ag-icon {
    font-size: 14px;
    line-height: 14px;
    padding: 6px;
  }

  .ag-header-icon > .ag-icon:hover {
    cursor: pointer;
    background: rgba(55, 55, 55, 0.1);
    border-radius: 20px;
  }

  .ag-row {
    cursor: pointer;
    color: #4e4e4e;
  }

  .ag-row-group {
    align-items: center;
  }

  .ag-row-selected {
    background-color: rgba(76, 175, 80, 0.4);
    font-weight: 400;
  }

  .ag-cell {
    display: flex;
    align-items: center;
  }

  .ag-cell > span {
    overflow: hidden;
  }

  .ag-cell > span > p {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ag-ltr,
  .ag-rtl {
    .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
    .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell,
    .ag-cell-focus.ag-cell-range-single-cell.ag-cell-range-handle {
      border: none;
      outline: none;
    }
  }

  .ag-column-drop-cell-text {
    font-weight: 400;
  }

  .ag-side-bar {
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: var(
      --ag-row-border-color,
      var(--ag-secondary-border-color, var(--ag-border-color, #e2e2e2))
    );
  }

  .ag-set-filter-list {
    height: auto;
  }
}
