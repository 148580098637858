@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin.scss";

.ag-theme-material {
  @include ag-theme-material(
    (
      material-primary-color: rgba(64, 139, 202, 1),
      material-accent-color: rgba(64, 139, 202, 1),
      row-hover-color: rgba(64, 139, 202, 0.2),
      borders-side-button: ag-derived(borders),
      font-family: (
        -apple-system,
        BlinkMacSystemFont,
        "Helvetica Neue",
        "Helvetica",
        sans-serif,
      ),
    )
  );

  .ag-row {
    cursor: pointer;
  }

  .ag-row-selected {
    background-color: #408bca;
    color: white;
    font-weight: 400;
  }

  .ag-ltr,
  .ag-rtl {
    .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
    .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell,
    .ag-cell-focus.ag-cell-range-single-cell.ag-cell-range-handle {
      border: none;
      outline: none;
    }
  }

  .ag-column-drop-cell-text {
    font-weight: 400;
  }

  .ag-side-bar {
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: var(
      --ag-row-border-color,
      var(--ag-secondary-border-color, var(--ag-border-color, #e2e2e2))
    );
  }

  .ag-set-filter-list {
    height: auto;
  }
}
